import React from 'react'
import { Backdrop, CircularProgress, ThemeProvider, CssBaseline, createMuiTheme, Box, Typography } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {main: "#000"}
  },
})

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Backdrop open={true} invisible={true}>
          <Box mr={3}><CircularProgress color="primary"/></Box>
          <Typography color="primary" variant="h4">Processing...</Typography>
        </Backdrop>
    </ThemeProvider>
  )
}
