import React from 'react'
import { Backdrop, ThemeProvider, CssBaseline, createMuiTheme, Box, Typography } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {main: "#000"}
  },
})

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Backdrop open={true} invisible={true}>
          <Box mr={3}><Typography color="primary" variant="h3"><span role="img" aria-label="warning">⚠️</span></Typography></Box>
          <Typography color="primary" variant="h4">Error Processing Request</Typography>
          <Box ml={3}><Typography color="primary" variant="h3"><span role="img" aria-label="warning">⚠️</span></Typography></Box>
        </Backdrop>
    </ThemeProvider>
  )
}
