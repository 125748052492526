import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'

import Callback from 'auth/Callback'
import Error from 'auth/Error'
import App from 'pages/App'

import { init as authInit, CallbackRoute, SecuredRoute } from "auth"
import './index.css'

(async () => {
  await authInit()

  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/error" exact component={Error} />
        <SecuredRoute path="/" exact loading={Callback} component={App} />
        <CallbackRoute path="/callback" exact component={Callback} error="/error" />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>,
    document.getElementById('root')
  )
})()