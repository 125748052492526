import React, { useEffect, useRef, useState } from 'react'
import './App.css'
import { Button, CssBaseline, ThemeProvider, createMuiTheme, Box, Container, Typography } from '@material-ui/core'
import JwtDecode from 'jwt-decode'
import moment from 'moment'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const prettyDiff = diff => {
  const hours = Math.floor(diff / 3600000)
  const minutes = Math.floor((diff - (hours * 3600000)) / 60000)
  const seconds = Math.floor((diff - (hours * 3600000) - (minutes * 60000)) / 1000)

  return `${(hours < 10)?`0${hours}`:hours}:${(minutes < 10)?`0${minutes}`:minutes}:${(seconds < 10)?`0${seconds}`:seconds}`
}

export default ({auth: {idToken, accessToken, resetLogin, logout}}) => {
  let [expDiff, setExpDiff] = useState("")
  let [diff, setDiff] = useState("")

  useInterval(() => {
    const id = JwtDecode(idToken())
    setDiff(prettyDiff((id.exp * 1000) - Date.now()))
    setExpDiff(prettyDiff((id.exp * 1000) - (id.iat*1000)))
  }, 1000)

  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
    }
  })

  const id = JwtDecode(idToken())
  const access = JwtDecode(accessToken())

  const exp = moment(id.exp * 1000).format("YYYY-MM-DD hh:mm:ss a")
  
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth="sm" alignContent="center">
        <Box display="flex" flexDirection="column" justifyContent="center" flexWrap>
          <Typography variant="h5">Token Lifetime</Typography>
          <pre>
            {expDiff}
          </pre>
          <Typography variant="h5">Token Expiration</Typography>
          <pre>
            {exp}
          </pre>
          <Typography variant="h5">Time to expiration</Typography>
          <pre>
            {diff}
          </pre>
          <Typography variant="h5">ID Token</Typography>
          <pre>
            {JSON.stringify(id, null, 2)}
          </pre>
          <Typography variant="h5">Access Token</Typography>
          <pre>
            {JSON.stringify(access, null, 2)}
          </pre>
          <Button color="primary" variant="contained" onClick={() => resetLogin()}>Reset Creds</Button>
          <br/>
          <Button color="primary" variant="contained" onClick={() => logout()}>Logout</Button>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
