import React from 'react'
import { Redirect, Route } from 'react-router'

import { UserManager } from 'oidc-client'
import { CookieStorage } from "cookie-storage"

import config from "./config"

var identityConfig, storage, userManager, user

export const init = async () => {
  identityConfig = {
    ...config,
    login: `${config.authority}/login`,
    redirect_uri: `${config.public_url}/callback`,
    post_logout_redirect_uri: `${config.public_url}`,
    automaticSilentRenew: false,
    silent_redirect_uri: `${config.public_url}/silent-renew`,
    loadUserInfo: false,
    audience: config.client_id,
    response_type: "id_token token"
  }

  storage = new CookieStorage({})

  const metadata = await fetch(identityConfig.authority + "/.well-known/openid-configuration")

  userManager = new UserManager({
    ...identityConfig,
    metadata: await metadata.json(),
    //userStore: new WebStorageStateStore({store: storage})
  })

  userManager.events.addUserLoaded(user => {
    const redirectUri = !!storage.getItem("redirectUri")
      ? storage.getItem("redirectUri")
      : "/"
    storage.removeItem("redirectUri")
    window.location.replace(redirectUri)
  })

  userManager.events.addAccessTokenExpired(() => redirectToLogin())
  user = await userManager.getUser()
}

const isAuthenticated = () => {
  return (user !== null)
}

const redirectToLogin = () => {
  userManager.removeUser().then(() => {
    storage.setItem("redirectUri", window.location.pathname)
    userManager.signinRedirect({})
  })
  return false
}

const resetLogin = () => {
  userManager.removeUser().then(() => window.location.reload())
}

const logout = () => {
  const idToken = user.id_token

  userManager.removeUser().then(() => {
    userManager.clearStaleState()
    userManager.signoutRedirect({
      id_token_hint: idToken
    })
  })
}


export const SecuredRoute = ({ component, ...rest}) => {
  const renderFn = Component => props => {
    if (!isAuthenticated()) {
      redirectToLogin()
      return rest.loading()
    } else {
      return <Component {...{auth: {
        resetLogin,
        logout,
        idToken: () => user && user.id_token,
        accessToken: () => user && user.access_token
      
      }, ...props}} />
    }
  }

  return <Route {...rest} render={renderFn(component)} />
}

export const CallbackRoute = ({ children, ...rest}) => {
  if (isAuthenticated()) {
    return <Redirect to={"/"} />
  } else {
    userManager.signinRedirectCallback(window.location.url).catch(ex=> {
      if (ex.message === "No matching state found in storage") {
        redirectToLogin()
        return ""
      }

      const errorLocation = rest.error?rest.error:"/error"
      window.location.replace(errorLocation + window.location.hash)
    })
  }

  return <Route {...rest}>{children}</Route>
}
