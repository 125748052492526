export default (() => {
  switch (window.location.host) {
    case "localhost:3000":
      return {
        authority: "https://login.microsoftonline.com/8f0b63e1-f094-4d6b-bae2-b495966c70fc/v2.0",
        client_id: "d5fa1819-57c5-42d4-83eb-e42cfeaee874",
        public_url: "http://localhost:3000",
        scope: "openid offline_access profile d5fa1819-57c5-42d4-83eb-e42cfeaee874/read:api",
        cookies: { 
          path: "/"
        }
      }
    case "paricui.paulkimbrel.com":
      return {
        authority: "https://login.microsoftonline.com/8f0b63e1-f094-4d6b-bae2-b495966c70fc/v2.0",
        client_id: "d5fa1819-57c5-42d4-83eb-e42cfeaee874",
        public_url: "https://paricui.paulkimbrel.com",
        scope: "openid offline_access profile d5fa1819-57c5-42d4-83eb-e42cfeaee874/read:api",
        cookies: { 
          path: "/"
        }
      }
    default:
      return {}
  }
})()